.asupvg1_product-carousel {
  display: none;

  @media (--only-desktop) {
    display: contents;
  }
}

.f19v7pn1_product-carousel {
  display: none;

  @media (--from-desktop) {
    display: contents;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvdHJpZmZsZS0tcHJvZHVjdC1jYXJvdXNlbF9tYXN0ZXIvc3JjL2NvbW1vbi9jb21wb25lbnRzL2FkYXB0aXZlL2Rlc2t0b3AudHMiXSwibmFtZXMiOlsiLmFzdXB2ZzFfcHJvZHVjdC1jYXJvdXNlbCIsIi5mMTl2N3BuMV9wcm9kdWN0LWNhcm91c2VsIl0sIm1hcHBpbmdzIjoiQUFHa0JBO0FBUUVDIiwiZmlsZSI6Ii9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvdHJpZmZsZS0tcHJvZHVjdC1jYXJvdXNlbF9tYXN0ZXIvc3JjL2NvbW1vbi9jb21wb25lbnRzL2FkYXB0aXZlL2Rlc2t0b3AudHMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBjc3MgfSBmcm9tICdsaW5hcmlhJztcbmltcG9ydCB7IGNyZWF0ZUFkYXB0aXZlQ29tcG9uZW50IH0gZnJvbSAnLi9jcmVhdGUtYWRhcHRpdmUtY29tcG9uZW50JztcblxuY29uc3QgYXREZXNrdG9wID0gY3NzYFxuICBkaXNwbGF5OiBub25lO1xuXG4gIEBtZWRpYSAoLS1vbmx5LWRlc2t0b3ApIHtcbiAgICBkaXNwbGF5OiBjb250ZW50cztcbiAgfVxuYDtcblxuY29uc3QgZnJvbURlc2t0b3AgPSBjc3NgXG4gIGRpc3BsYXk6IG5vbmU7XG5cbiAgQG1lZGlhICgtLWZyb20tZGVza3RvcCkge1xuICAgIGRpc3BsYXk6IGNvbnRlbnRzO1xuICB9XG5gO1xuXG5leHBvcnQgY29uc3QgQXREZXNrdG9wID0gY3JlYXRlQWRhcHRpdmVDb21wb25lbnQoe1xuICBkaXNwbGF5TmFtZTogJ0F0RGVza3RvcCcsXG4gIHNzckNsYXNzTmFtZTogYXREZXNrdG9wLFxufSk7XG5cbmV4cG9ydCBjb25zdCBGcm9tRGVza3RvcCA9IGNyZWF0ZUFkYXB0aXZlQ29tcG9uZW50KHtcbiAgZGlzcGxheU5hbWU6ICdGcm9tRGVza3RvcCcsXG4gIHNzckNsYXNzTmFtZTogZnJvbURlc2t0b3AsXG59KTtcbiJdfQ==*/