.c903ckl_product-carousel {
  @media (--only-mobile) {
    &:empty {
      display: none;
    }
  }
}

.o1s63dix_product-carousel {
  margin-top: var(--space-150);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvdHJpZmZsZS0tcHJvZHVjdC1jYXJvdXNlbF9tYXN0ZXIvc3JjL2NvbW1vbi9jb21wb25lbnRzL3Byb2R1Y3QtY2FyZC9wcm9kdWN0LW9ubGluZS1vcmRlci1vbmx5LnRzeCJdLCJuYW1lcyI6WyIuYzkwM2NrbF9wcm9kdWN0LWNhcm91c2VsIiwiLm8xczYzZGl4X3Byb2R1Y3QtY2Fyb3VzZWwiXSwibWFwcGluZ3MiOiJBQW1CeUJBO0FBUU1DIiwiZmlsZSI6Ii9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvdHJpZmZsZS0tcHJvZHVjdC1jYXJvdXNlbF9tYXN0ZXIvc3JjL2NvbW1vbi9jb21wb25lbnRzL3Byb2R1Y3QtY2FyZC9wcm9kdWN0LW9ubGluZS1vcmRlci1vbmx5LnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tICdyZWFjdCc7XG5pbXBvcnQgeyB1c2VUcmFuc2xhdGlvbiB9IGZyb20gJ3JlYWN0LWkxOG5leHQnO1xuaW1wb3J0IHsgT25saW5lT3JkZXJPbmx5IH0gZnJvbSAnQG5hdmktdG9vbGJveC91aS1jb21wb25lbnRzJztcbmltcG9ydCB7IGNzcyB9IGZyb20gJ2xpbmFyaWEnO1xuXG5leHBvcnQgY29uc3QgUHJvZHVjdE9ubGluZU9yZGVyT25seTogUmVhY3QuRkMgPSAoKSA9PiB7XG4gIGNvbnN0IHsgdCB9ID0gdXNlVHJhbnNsYXRpb24oKTtcblxuICByZXR1cm4gKFxuICAgIDxkaXYgY2xhc3NOYW1lPXtvbmxpbmVPcmRlck9ubHl9PlxuICAgICAgPE9ubGluZU9yZGVyT25seVxuICAgICAgICB0ZXh0PXt0KCdwcm9kdWN0LWNhcmQub25saW5lLW9yZGVyLW9ubHknKX1cbiAgICAgICAgY2xhc3NOYW1lPXtjb250YWluZXJ9XG4gICAgICAgIGRhdGEtcWE9XCJvbmxpbmUtb3JkZXItb25seVwiXG4gICAgICAvPlxuICAgIDwvZGl2PlxuICApO1xufTtcblxuZXhwb3J0IGNvbnN0IGNvbnRhaW5lciA9IGNzc2BcbiAgQG1lZGlhICgtLW9ubHktbW9iaWxlKSB7XG4gICAgJjplbXB0eSB7XG4gICAgICBkaXNwbGF5OiBub25lO1xuICAgIH1cbiAgfVxuYDtcblxuZXhwb3J0IGNvbnN0IG9ubGluZU9yZGVyT25seSA9IGNzc2BcbiAgbWFyZ2luLXRvcDogdmFyKC0tc3BhY2UtMTUwKTtcbmA7XG4iXX0=*/