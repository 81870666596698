import { css } from 'linaria';

export const arrowStyles = css`
  &::before {
    position: absolute;
    z-index: 8999;
    width: 0;
    height: 0;
    border-width: 6px 6px 0;
    border-style: solid;
    border-color: var(--accent-light) transparent transparent transparent;
    transform: rotate(0deg);
    content: '';
  }
`;

export const tooltipStyles = css`
  z-index: 9000;
  width: 288px;
  background: var(--accent-light);
  border-radius: var(--rounding-m);
  box-shadow: var(--box-shadow-s);
  opacity: 1;
  transition: opacity 0.2s;
  pointer-events: none;
  will-change: opacity;

  &::after {
    position: absolute;
    content: '';
    z-index: 9000;
    background-color: var(--accent-light);
  }

  &[data-popper-placement^='top'] {
    &::after {
      left: 5%;
      bottom: 0;
      width: 90%;
      height: 6px;
    }
    .${arrowStyles} {
      bottom: 0;

      &::before {
        transform: rotate(0deg);
        filter: drop-shadow(0 1px 4px rgb(0 0 0 / 16%)) drop-shadow(0 2px 9px rgb(0 0 0 / 2%));
      }
    }
  }

  &[data-popper-placement^='bottom'] {
    &::after {
      top: 0;
      left: 5%;
      width: 90%;
      height: 6px;
    }
    .${arrowStyles} {
      top: -6px;

      &::before {
        transform: rotate(180deg);
        filter: drop-shadow(0 -1px 4px rgb(0 0 0 / 16%)) drop-shadow(0 -2px 9px rgb(0 0 0 / 2%));
      }
    }
  }

  &[data-popper-placement^='left'] {
    &::after {
      right: 0;
      bottom: 5%;
      width: 6px;
      height: 90%;
    }
    .${arrowStyles} {
      right: 3px;

      &::before {
        transform: rotate(270deg);
        filter: drop-shadow(1px 0 4px rgb(0 0 0 / 16%)) drop-shadow(2px 0 9px rgb(0 0 0 / 2%));
      }
    }
  }

  &[data-popper-placement^='right'] {
    &::after {
      left: 0;
      bottom: 5%;
      width: 6px;
      height: 90%;
    }
    .${arrowStyles} {
      left: -9px;

      &::before {
        transform: rotate(90deg);
        filter: drop-shadow(1px 0 4px rgb(0 0 0 / 16%)) drop-shadow(2px 0 9px rgb(0 0 0 / 2%));
      }
    }
  }
`;

export const inModal = css`
  z-index: 10000;
`;

export const disabled = css`
  opacity: 0;
  transition-delay: initial;
`;

export const wrapper = css`
  position: relative;
  width: 100%;
`;
