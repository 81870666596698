.c9re4cz_product-carousel {
  position: absolute;
  top: 50%;
  left: var(--c9re4cz-0);
  right: var(--c9re4cz-1);
  z-index: 2;
  margin-right: 0;
  transform: translateY(-50%) translateX(var(--c9re4cz-2));
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvdHJpZmZsZS0tcHJvZHVjdC1jYXJvdXNlbF9tYXN0ZXIvc3JjL2NvbW1vbi9jb21wb25lbnRzL2Nhcm91c2VsL3N0eWxlcy50cyJdLCJuYW1lcyI6WyIuYzlyZTRjel9wcm9kdWN0LWNhcm91c2VsIl0sIm1hcHBpbmdzIjoiQUFHNENBIiwiZmlsZSI6Ii9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvdHJpZmZsZS0tcHJvZHVjdC1jYXJvdXNlbF9tYXN0ZXIvc3JjL2NvbW1vbi9jb21wb25lbnRzL2Nhcm91c2VsL3N0eWxlcy50cyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB7IEVsZW1lbnRUeXBlIH0gZnJvbSAncmVhY3QnO1xuaW1wb3J0IHsgc3R5bGVkIH0gZnJvbSAnbGluYXJpYS9yZWFjdCc7XG5cbmV4cG9ydCBjb25zdCBDYXJvdXNlbENvbnRyb2w6IEVsZW1lbnRUeXBlID0gc3R5bGVkLmRpdjx7XG4gIGRpcmVjdGlvbj86ICdsZWZ0JyB8ICdyaWdodCc7XG59PmBcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICB0b3A6IDUwJTtcbiAgbGVmdDogJHsoeyBkaXJlY3Rpb24gfSkgPT4gKGRpcmVjdGlvbiA9PT0gJ2xlZnQnID8gJ3ZhcigtLWNhcm91c2VsLWNvbnRyb2wtbWFyZ2luLCAwKScgOiAnaW5oZXJpdCcpfTtcbiAgcmlnaHQ6ICR7KHsgZGlyZWN0aW9uIH0pID0+IChkaXJlY3Rpb24gPT09ICdyaWdodCcgPyAndmFyKC0tY2Fyb3VzZWwtY29udHJvbC1tYXJnaW4sIDApJyA6ICdpbmhlcml0Jyl9O1xuICB6LWluZGV4OiAyO1xuICBtYXJnaW4tcmlnaHQ6IDA7XG4gIHRyYW5zZm9ybTogdHJhbnNsYXRlWSgtNTAlKSB0cmFuc2xhdGVYKCR7KHsgZGlyZWN0aW9uIH0pID0+IChkaXJlY3Rpb24gPT09ICdsZWZ0JyA/ICctNTAlJyA6ICc1MCUnKX0pO1xuYDtcbiJdfQ==*/