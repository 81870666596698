.s164t3cc_product-carousel {
  position: relative;
  width: 100%;

  @media (--to-desktop) {
    overflow: hidden;
  }
}

.sm4xxx_product-carousel {
  width: 100%;
  display: flex;
  gap: var(--space-150);
  overflow-x: scroll;
  scroll-snap-type: var(--sm4xxx-0);
  scroll-padding: var(--carousel-scroll-padding, 250px);
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (--to-desktop) {
    scroll-padding: auto;
    scroll-snap-type: none;
  }

  @media (--only-mobile) {
    padding-right: var(--space-200);
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvdHJpZmZsZS0tcHJvZHVjdC1jYXJvdXNlbF9tYXN0ZXIvc3JjL2NvbW1vbi9jb21wb25lbnRzL2Nhcm91c2VsL3ZhcmlhbnRzL3Nwb25zb3JlZC50cyJdLCJuYW1lcyI6WyIuczE2NHQzY2NfcHJvZHVjdC1jYXJvdXNlbCIsIi5zbTR4eHhfcHJvZHVjdC1jYXJvdXNlbCJdLCJtYXBwaW5ncyI6IkFBS3NDQTtBQVNTQyIsImZpbGUiOiIvZGF0YS9qZW5raW5zLXJvb3Qvd29ya3NwYWNlL3RyaWZmbGUtLXByb2R1Y3QtY2Fyb3VzZWxfbWFzdGVyL3NyYy9jb21tb24vY29tcG9uZW50cy9jYXJvdXNlbC92YXJpYW50cy9zcG9uc29yZWQudHMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBjc3MgfSBmcm9tICdsaW5hcmlhJztcbmltcG9ydCB7IHN0eWxlZCB9IGZyb20gJ2xpbmFyaWEvcmVhY3QnO1xuaW1wb3J0IHsgRWxlbWVudFR5cGUgfSBmcm9tICdyZWFjdCc7XG5pbXBvcnQgeyB3aGljaEJyb3dzZXIgfSBmcm9tICd1dGlscy9icm93c2Vycyc7XG5cbmV4cG9ydCBjb25zdCBzcG9uc29yZWRXcmFwcGVyU3R5bGVzID0gY3NzYFxuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIHdpZHRoOiAxMDAlO1xuXG4gIEBtZWRpYSAoLS10by1kZXNrdG9wKSB7XG4gICAgb3ZlcmZsb3c6IGhpZGRlbjtcbiAgfVxuYDtcblxuZXhwb3J0IGNvbnN0IFNwb25zb3JlZENvbnRhaW5lcjogRWxlbWVudFR5cGUgPSBzdHlsZWQuZGl2PHsgaW5TY3JvbGxOb3c6IGJvb2xlYW4gfT5gXG4gIHdpZHRoOiAxMDAlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBnYXA6IHZhcigtLXNwYWNlLTE1MCk7XG4gIG92ZXJmbG93LXg6IHNjcm9sbDtcbiAgc2Nyb2xsLXNuYXAtdHlwZTogJHsoeyBpblNjcm9sbE5vdyB9KSA9PlxuICAgIGluU2Nyb2xsTm93ICYmIHdoaWNoQnJvd3NlcigpICE9PSAnc2FmYXJpJyA/ICdub25lJyA6ICd4IHByb3hpbWl0eSd9O1xuICBzY3JvbGwtcGFkZGluZzogdmFyKC0tY2Fyb3VzZWwtc2Nyb2xsLXBhZGRpbmcsIDI1MHB4KTtcbiAgb3ZlcnNjcm9sbC1iZWhhdmlvci14OiBjb250YWluO1xuICAtd2Via2l0LW92ZXJmbG93LXNjcm9sbGluZzogdG91Y2g7XG4gIC1tcy1vdmVyZmxvdy1zdHlsZTogbm9uZTtcbiAgc2Nyb2xsYmFyLXdpZHRoOiBub25lO1xuXG4gICY6Oi13ZWJraXQtc2Nyb2xsYmFyIHtcbiAgICBkaXNwbGF5OiBub25lO1xuICB9XG5cbiAgQG1lZGlhICgtLXRvLWRlc2t0b3ApIHtcbiAgICBzY3JvbGwtcGFkZGluZzogYXV0bztcbiAgICBzY3JvbGwtc25hcC10eXBlOiBub25lO1xuICB9XG5cbiAgQG1lZGlhICgtLW9ubHktbW9iaWxlKSB7XG4gICAgcGFkZGluZy1yaWdodDogdmFyKC0tc3BhY2UtMjAwKTtcbiAgfVxuYDtcbiJdfQ==*/