.f11n7m8x_product-carousel {
  display: none;

  @media (--from-tablet) {
    display: contents;
  }
}

.a1o5f6ea_product-carousel {
  display: none;

  @media (--only-tablet) {
    display: contents;
  }
}

.t1a4hlnw_product-carousel {
  display: contents;

  @media (--from-desktop) {
    display: none;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvdHJpZmZsZS0tcHJvZHVjdC1jYXJvdXNlbF9tYXN0ZXIvc3JjL2NvbW1vbi9jb21wb25lbnRzL2FkYXB0aXZlL3RhYmxldC50cyJdLCJuYW1lcyI6WyIuZjExbjdtOHhfcHJvZHVjdC1jYXJvdXNlbCIsIi5hMW81ZjZlYV9wcm9kdWN0LWNhcm91c2VsIiwiLnQxYTRobG53X3Byb2R1Y3QtY2Fyb3VzZWwiXSwibWFwcGluZ3MiOiJBQUdtQkE7QUFRRkM7QUFRQUMiLCJmaWxlIjoiL2RhdGEvamVua2lucy1yb290L3dvcmtzcGFjZS90cmlmZmxlLS1wcm9kdWN0LWNhcm91c2VsX21hc3Rlci9zcmMvY29tbW9uL2NvbXBvbmVudHMvYWRhcHRpdmUvdGFibGV0LnRzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgY3NzIH0gZnJvbSAnbGluYXJpYSc7XG5pbXBvcnQgeyBjcmVhdGVBZGFwdGl2ZUNvbXBvbmVudCB9IGZyb20gJy4vY3JlYXRlLWFkYXB0aXZlLWNvbXBvbmVudCc7XG5cbmNvbnN0IGZyb21UYWJsZXQgPSBjc3NgXG4gIGRpc3BsYXk6IG5vbmU7XG5cbiAgQG1lZGlhICgtLWZyb20tdGFibGV0KSB7XG4gICAgZGlzcGxheTogY29udGVudHM7XG4gIH1cbmA7XG5cbmNvbnN0IGF0VGFibGV0ID0gY3NzYFxuICBkaXNwbGF5OiBub25lO1xuXG4gIEBtZWRpYSAoLS1vbmx5LXRhYmxldCkge1xuICAgIGRpc3BsYXk6IGNvbnRlbnRzO1xuICB9XG5gO1xuXG5jb25zdCB0b1RhYmxldCA9IGNzc2BcbiAgZGlzcGxheTogY29udGVudHM7XG5cbiAgQG1lZGlhICgtLWZyb20tZGVza3RvcCkge1xuICAgIGRpc3BsYXk6IG5vbmU7XG4gIH1cbmA7XG5cbmV4cG9ydCBjb25zdCBGcm9tVGFibGV0ID0gY3JlYXRlQWRhcHRpdmVDb21wb25lbnQoe1xuICBkaXNwbGF5TmFtZTogJ0Zyb21UYWJsZXQnLFxuICBzc3JDbGFzc05hbWU6IGZyb21UYWJsZXQsXG59KTtcblxuZXhwb3J0IGNvbnN0IEF0VGFibGV0ID0gY3JlYXRlQWRhcHRpdmVDb21wb25lbnQoe1xuICBkaXNwbGF5TmFtZTogJ0F0VGFibGV0JyxcbiAgc3NyQ2xhc3NOYW1lOiBhdFRhYmxldCxcbn0pO1xuXG5leHBvcnQgY29uc3QgVG9UYWJsZXQgPSBjcmVhdGVBZGFwdGl2ZUNvbXBvbmVudCh7XG4gIGRpc3BsYXlOYW1lOiAnVG9UYWJsZXQnLFxuICBzc3JDbGFzc05hbWU6IHRvVGFibGV0LFxufSk7XG4iXX0=*/