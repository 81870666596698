import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnlineOrderOnly } from '@navi-toolbox/ui-components';
import { css } from 'linaria';

export const ProductOnlineOrderOnly: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={onlineOrderOnly}>
      <OnlineOrderOnly
        text={t('product-card.online-order-only')}
        className={container}
        data-qa="online-order-only"
      />
    </div>
  );
};

export const container = css`
  @media (--only-mobile) {
    &:empty {
      display: none;
    }
  }
`;

export const onlineOrderOnly = css`
  margin-top: var(--space-150);
`;
