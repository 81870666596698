import { css } from 'linaria';
import { container } from 'common/components/product-card/product-online-order-only';

export const modalContent = css`
  & > div:first-of-type {
    display: flex;
    flex-direction: column;
  }
`;

export const pageContentStyles = css`
  width: 100% !important;
  max-width: var(--fronton-layout-width);
  padding: 0 !important;
`;

export const carouselWrapperStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

// needed to avoid broken clipPath on webkit
export const helperRating = css`
  position: absolute !important;
  left: -9999px;
`;

export const topLineStyles = css`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  min-height: 32px;

  @media (--only-mobile) {
    margin-bottom: 16px;
  }

  @media (--from-tablet) {
    margin-bottom: 14px;
  }
`;

export const topLineItemStyles = css`
  @media (--from-tablet) {
    &:not(&:last-of-type) {
      margin-right: var(--space-250);
    }
  }
`;

export const titleStyle = css`
  color: var(--text-primary);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  text-transform: uppercase !important;
`;

export const topLineSeeMoreStyles = css`
  position: relative;
  top: -4px;
  white-space: nowrap;
`;

export const carouselStyles = css`
  margin-top: var(--space-125);
`;

export const carouselItemStyles = css`
  --item-width: 280px;

  position: relative;
  min-width: var(--item-width);
  width: var(--item-width);
  margin-right: var(--space-250);

  @media (--only-mobile) {
    margin-right: 0;
  }

  @media (--from-tablet) {
    --item-width: 212px;
  }

  @media (--from-desktop) {
    --item-width: 220px;
  }

  @media (--from-largeDesktop) {
    --item-width: 280px;
  }
`;

export const seeMoreCarouselItem = css`
  margin-right: 0;
`;

export const productCardStyles = css`
  &.large-card,
  &.list {
    box-shadow: none !important;
  }
`;

export const controlStyles = css`
  box-shadow: var(--box-shadow-m);
`;

export const seeMoreCardStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: var(--space-450);
  text-decoration: none;

  @media (--from-tablet) and (--only-mobile) {
    min-width: auto;
  }
`;

export const seeMoreCaption = css`
  margin-top: var(--space-150) !important;
`;

export const bottomLineStyles = css`
  margin-top: var(--space-150);
  display: inline-block;
  width: 100%;
`;

export const bottomSeeMoreStyles = css`
  width: 100%;
  justify-content: space-between !important;
`;

export const mobileItem3p = css``;

export const mobileItemCommon = css``;

export const mobileItem = css`
  @media (--to-tablet) {
    position: relative;

    &:nth-child(even) {
      border-bottom: 1px solid var(--control-minor);
    }

    &:nth-child(odd) {
      border: 1px solid var(--control-minor);
      border-top: 0;
      border-left: 0;
      &:has(+ .${mobileItem3p}) {
        ${container} {
          display: flex;
        }
      }

      &.${mobileItem3p} {
        & + .${mobileItemCommon} {
          ${container} {
            display: flex;
          }
        }
      }
    }
  }
`;

export const flatListStyles = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: fill-available;

  & > *:nth-child(odd) {
    [data-product-item] {
      padding-left: 0;
    }
  }

  & > *:nth-child(even) {
    [data-product-item] {
      padding-right: 0;
    }
  }
`;
